import Navbar from "../../components/navbar/Navbar";
import Featured from "../../components/featured/Featured";
import "./home.scss";
import List from "../../components/list/List";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import Membership from "../membership/Membership";

const Home = ({type}) => {
  const [lists, setLists] = useState([]);
  const [canales, setCanales] = useState([]);
  const [estadom, setEstadom] = useState(true);

  var lleno=0

  const {id} = useParams();
  
  var res;
   
  useEffect(() => {
    const getRandomLists = async () => {
    var urlyotta=localStorage.getItem("url")

      if(id===0 || id === undefined)
      {
        
        var token2=localStorage.getItem("user").replace(/["]+/g, '');

        try {
          const res = await axios.get(
            `${urlyotta}/channels/`,
            {
              headers: {
                'Authorization':
                `Bearer ${token2}`
              },
            }
          );
          
          //console.log("channels");
          //console.log(res.data.channels);
          //console.log(res.data.categories);
          setLists(res.data.categories);
          setCanales(res.data.channels);
        } catch (err) {
          console.log(err);
        }
        
      }
      else{
        
        var token2=localStorage.getItem("user").replace(/["]+/g, '');
        try {
          const categoriacanal = await axios.get(
            `${urlyotta}/channels/?category=${id}`,
            {
              headers: {
                'Authorization':
                `Bearer ${token2}`
              },
            }
          );
          console.log("categoriascanal7");
          console.log(categoriacanal.data);
          setLists(categoriacanal.data.categories);
          setCanales(categoriacanal.data.channels);

          
        } catch (err) {
          console.log(err);
        }
  
      }

      var token3=localStorage.getItem("user").replace(/["]+/g, '');
      
      console.log("urlllllllmembresi",urlyotta);
    if(urlyotta=="https://tvmanager.vuela.bo"){  
      try {
        const fecha = await axios.get(
          `${urlyotta}/user/me/`,
          {
            headers: {
              'Authorization':
              `Bearer ${token3}`
            },

            
          }
        );
        console.log("fecha dentro del if");
        console.log(fecha.data.data.activate);

        let estado;
        estado = fecha.data.data.activate;
        if(estado == null)
        {

          setEstadom(false);

        }else{

          console.log("aquiiiiiiiiiiii")
          var hoy = new Date().toISOString().slice(0, 10);
          let mem;
          mem = estado.split('-');
          console.log(mem);
          let memh;
          memh = hoy.split('-');
          memh[2]=(memh[2]-1).toString();
          console.log(memh);
          
          if ( mem >= memh){
           setEstadom(true);
            console.log("tiene membresía");
          } else {
            setEstadom(false);
            console.log("no tiene memebresia activa");
           }

        
        }

        
      } catch (err) {
        console.log(err);
      }
    }else{
      setEstadom(true);
    }
      
    };
    getRandomLists();

  }, [id]);

  return (

    estadom==true?


    <div className="homeinicio">
      
      <Featured type={type} cate={lists}/>
      
    </div>
    :
    <Membership/>
  );
};

export default Home;
