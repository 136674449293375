import Grid from '@mui/material/Grid';
import Item from '@mui/material/ListItem';
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import "./featured.scss";
import Watch from "../../pages/watch/Watch";
import { Link,useHistory } from "react-router-dom";
import List from '../list/List';
import ListItem from '../listItem/ListItem';
import ListaItem from '../listaitem/ListaItem';
import { ArrowBackOutlined ,ArrowDropDown } from "@material-ui/icons";
export default function Featuredgrab({ type,cate }) {
  let history = useHistory();

  function handleChange(value) {
    history.push(`/`);
    history.push(`categoria/${value}`);
  }
  const [lists, setLists] = useState([]);
  const [canales, setCanales] = useState([]);
  const [estadom, setEstadom] = useState(true);

  var lleno=0

  const {id} = "11";
  
  var res;
   
  useEffect(() => {
    const getRandomLists = async () => {
    var urlyotta=localStorage.getItem("url")
  
        var token2=localStorage.getItem("user").replace(/["]+/g, '');
        try {
          const categoriacanal = await axios.get(
            `${urlyotta}/channels/?category=${type}`,
            {
              headers: {
                'Authorization':
                `Bearer ${token2}`
              },
            }
          );
          console.log("categoriascanal7..........................................................");
          console.log(categoriacanal.data);
          setLists(categoriacanal.data.categories);
          setCanales(categoriacanal.data.channels);

          
        } catch (err) {
          console.log(err);
        }
  
      

      var token3=localStorage.getItem("user").replace(/["]+/g, '');
      
      console.log("urlllllllmembresi",urlyotta);
    if(urlyotta=="https://tvmanager.vuela.bo"){  
      try {
        const fecha = await axios.get(
          `${urlyotta}/user/me/`,
          {
            headers: {
              'Authorization':
              `Bearer ${token3}`
            },

            
          }
        );
        console.log("fecha dentro del if");
        console.log(fecha.data.data.activate);

        let estado;
        estado = fecha.data.data.activate;
        if(estado == null)
        {

          setEstadom(false);

        }else{

          console.log("aquiiiiiiiiiiii")
          var hoy = new Date().toISOString().slice(0, 10);
          let mem;
          mem = estado.split('-');
          console.log(mem);
          let memh;
          memh = hoy.split('-');
          memh[2]=(memh[2]-1).toString();
          console.log(memh);
          
          if ( mem >= memh){
           setEstadom(true);
            console.log("tiene membresía");
          } else {
            setEstadom(false);
            console.log("no tiene memebresia activa");
           }

        
        }

        
      } catch (err) {
        console.log(err);
      }
    }else{
      setEstadom(true);
    }
      
    };
    getRandomLists();

  }, [id]);

  return (
   
    type=="6"?

   <div className="fnuevo">

   <h1 className='titulo'>Camaras en vivo</h1>
   <Link to = "/login">
    
          <div className="back">
            <ArrowBackOutlined>
                
            </ArrowBackOutlined>
           
          </div>
          </Link>
   
   
    
    <br></br>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
  {canales.map((item, i) => (
    <Grid item xs={4} sm={4} md={4} key={i}>
       <ListaItem key={i} item={item} index={i} catego={cate}/>
    </Grid>
        ))}
    </Grid>

    
    </div>:
    <div className="fnuevo">

    <h1 className='titulo'>Grabaciones</h1>
    <Link to = "/">
    
          <div className="back">
            <ArrowBackOutlined>
                
            </ArrowBackOutlined>
           
          </div>
          </Link>
   
    <br></br>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
  {canales.map((item, i) => (
    <Grid item xs={4} sm={4} md={4} key={i}>
       <ListaItem key={i} item={item} index={i} catego={cate}/>
    </Grid>
        ))}
    </Grid>

    
    </div>
  );
}
