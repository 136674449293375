import { ArrowBackOutlined ,ArrowDropDown } from "@material-ui/icons";
import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import { useState, useEffect } from "react";
import "./watch.scss";
import ReactPlayer from 'react-player'
import { useLocation } from "react-router";
import {  useHistory  } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import Lista from "../../components/lista/Lista";

export default function Watch() {

  const [player, setplayer] = useState(false);
  const [canales, setCanales] = useState();
  const [lista, setList] = useState();
  const [actual, setActual] = useState();
  const [loading, setLoading] = useState(true);
  let history = useHistory();  
  const location = useLocation();
  console.log("holaaaaaaaaaaa");
  console.log(location.state.item.stream);
  


  function hola(){
    setplayer(true);
  }
  return (
    <div>
    
    <div className="watch">
      
      <div className="back">
        
        <button className="btnback" onClick={() => history.goBack()} >
                Volver
        </button>
              
      </div>
     
      <ReactPlayer className='player'
          // This is the video address passed from the superior page
          url={location.state.item.stream}
          playsinline={true}
          playing={true}
          loop={false}
          muted={true}
          height='100%'
          width='100%' 
          controls={true}
          config={{
          wistia: {
            options: {
              autoPlay: false,
              controlsVisibleOnLoad: false,
              muted: false,
              silentAutoPlay: false,
              volumeControl: false,
              wmode: "transparent",
              playPauseNotifier: false
            }
          }
        }}
    />
      
          
      
      
    </div>

    </div>
  )

}
