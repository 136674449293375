import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import "./featured.scss";
import Watch from "../../pages/watch/Watch";
import { Link,useHistory } from "react-router-dom";
import { logout } from "../../authContext/AuthActions";
import { AuthContext } from "../../authContext/AuthContext";
import { useContext, useState } from "react";
import image from "./vuela.png";

export default function Featured({ type,cate }) {
  let history = useHistory();
  const {dispatch}=useContext(AuthContext)


  function handleChange(value) {
    history.push(`/`);
    history.push(`categoria/${value}`);
  }
  function salir() {

    var token2=localStorage.getItem("user").replace(/["]+/g, '');

    fetch("https://tvmanager.vuela.bo/auth/logout/",{
      method:"POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Bearer ${token2}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response)=>{
      console.log(response);
      dispatch(logout());
      localStorage.removeItem("user");

    })



  }
  return (
   
   <div className="feat">
    <img
          src={image}
          alt=""
        />
        
    <h1 className="info">Vuela Security</h1>

      <div className="lef">
        
            <Link to="/" className="link">
              
              <button onClick={()=>salir()} className="btnsalir" >
                Salir
            </button>
            </Link>

        </div>  

        
      <div className="right">
              
              <Link to="/vivo">
                <button className="btn" >
                    Camaras en vivo
                </button>
                  
              </Link>
                  
              <Link to="/grabaciones">
                <button className="btn" >
                    Grabaciones
                </button>  
              </Link>
                  
        
      </div>

      
    </div>
  );
}
