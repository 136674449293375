import "./App.scss"
import Home from "./pages/home/Home";
import Register from "./pages/register/Register";
import Watch from "./pages/watch/Watch";
import Login from "./pages/login/Login";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./authContext/AuthContext";
import Privacy from "./pages/privacy/Privacy";
import Recovery from "./pages/recovery/Recovery";
import Grabado from "./components/grabaciones/grabado";
import Vivo from "./components/vivo/vivo";

const App = () => {

  let usert;
  let user;
 let err;
 let errt;
 
 //console.log("app.js");
 //console.log(useContext(AuthContext).user);
 //console.log(useContext(AuthContext).url);

 usert=useContext(AuthContext).user;
 err=useContext(AuthContext).error;
 //console.log("usertttt",usert);
 //console.log("errrr",err);
  
 if( usert=== null || usert === 0 ){
    user=false
    //console.log("es null o cero")
  }
  else{
    user=true;
    //console.log("no es null ni cero")
  }
  if( err=== "Cuenta creada correctamente" ){
    errt=true
    //console.log("es null o cero")
  }
  else{
    errt=false
    //console.log("no es null ni cero")
  }
 
  return (
    
    <Router>
      <Switch>
        <Route exact path="/privacy">
           <Privacy></Privacy>
        </Route>
        <Route path="/categoria/:id">
          <Home></Home>
        </Route>
        <Route exact path="/">
          {user ? <Home /> : <Redirect to="/login" />}
        </Route>
        <Route path="/recuperar">
          {!errt ? <Recovery /> : <Redirect to="/login" />}
        </Route>
        <Route path="/register">
          {!errt ? <Register /> : <Redirect to="/login" />}
        </Route>
        
        <Route path="/login">{!user ? <Login /> : <Redirect to="/" />}</Route>
        {user && (
          <>
            <Route path="/movies">
              <Home type="movies" />
            </Route>
            <Route path="/series">
              <Home type="series" />
            </Route>
        
            
            <Route path="/watch">
              <Watch/>
            </Route>
            <Route path="/grabaciones">
              <Grabado/>
            </Route>
            <Route path="/vivo">
              <Vivo/>
            </Route>
            
          </>
        )}
      </Switch>
    </Router>
  );
};

export default App;