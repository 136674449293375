import { useRef } from "react";
import { useState } from "react";
import { Link,useHistory } from "react-router-dom";
import "./Recovery.scss";
import React from 'react';
import ReactDOM from 'react-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { recuperar, register } from "../../authContext/apiCalls";
import { useContext } from "react";
import { AuthContext } from "../../authContext/AuthContext";
import image from "./logo.png";
import { ArrowBackOutlined ,ArrowDropDown } from "@material-ui/icons";

export default function Recovery() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setNombre] = useState("");
  const [last_name, setApellido] = useState("");
  const [cellphone, setTelefono] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [validtel, setValidtel] = useState("");
  const [errorform, setErrorform] = useState(null);
  const [iniciar, setIniciar] = useState(0);
  const { dispatch } = useContext(AuthContext);
  let usert=useContext(AuthContext).error;
  let u=useContext(AuthContext).user;

  let history = useHistory();

  

  function handlePhoneNumberChange (
    isValid,
    rawValue,
    countryData,
    formattedValue,
    extension
  ) {
    setValidtel(isValid);
    if(isValid){
      let telefonof;
      telefonof="+"+countryData.dialCode+"-"+rawValue;
      console.log("enviar",telefonof); 
      setTelefono(telefonof);
    }
  }

  const handleRegister = (e) => {
    e.preventDefault();
    setErrorform("");
    
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)){
      
            console.log("todo llenito");
            
            recuperar({ email}, dispatch);
            setTimeout(
              setIniciar(1),
            setErrorform("Se envió un link de recuperación al correo :"+email)
            
              , 5000);
            
      } else {
        setErrorform("Ingrese un email válido");
      }
    
    }
  
  const handleRegister2 = (e) => {
    e.preventDefault();
    history.push(`/login`);
    }
 
  return (
    <div className="recuperar">
      <div className="top">
        <div className="wrapper">
          
        </div>
      </div>
      <div className="container">
        <form>
        <Link to = "/">
    
    <div className="back">
      <ArrowBackOutlined>
    
      </ArrowBackOutlined>

    </div>
  </Link>
<br></br>


          <h1>Recuperar cuenta</h1>
          <br></br>
          <h3>Ingrese su correo electrónico</h3>
          <input
            type="email"
            placeholder="Email "
            onChange={(e) => setEmail(e.target.value)}
          />
        
          <p>{errorform}</p>          
          {iniciar==0?
            <button className="loginButton" onClick={handleRegister} >
                Ingresar
            </button>:
            <button className="loginButton" onClick={handleRegister2} >
                Iniciar Sesión
            </button>
        }            
       
          
        </form>
      </div>
    </div>  );
}
